export default {
	namespaced: true,

	state: {
		caller: {
			dialog: false
		},
		search: {
			searchField: ''
		}
	},

	// mutations: {
	// 	closeDialog (state) {
	// 		state.dialog = false;
	// 	},
	// 	clearData (state) {
	// 		state.form = {}
	// 	}
	// },
	//
	// actions: {
	// 	login ({ commit, state }) {
	// 		axios
	// 			.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'user/auth', state.form)
	// 			.then(() => {
	// 				commit('clearData')
	// 				commit('closeDialog')
	// 			})
	// 	},
	// 	clear ({ commit }) {
	// 		commit('clearData')
	// 	}
	// },
}
