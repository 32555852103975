<template>
    <div class="">
		<div class="d-flex">
			<input-text
				v-model="search"
				append-icon="search"
				class="textfield-custom2"
			/>
			<div class="">
				<v-btn depressed x-large class="blue px-10 white--text ml-8" :to="{name: 'blog'}">Добавить</v-btn>
			</div>
        </div>
        <v-card class="elevation-0 mt-10">
			<v-card-text class="px-6">
				<datatable
					:headers="datatableheaders"
					:items="datatabledata"
					:itemSlots="['item.is_active', 'item.title']"
					:search="search"
				>
					<template v-slot:item.title="{item}">
						<router-link :to="{ name: 'blog', params: {id: item.id} }">{{item.content.title}}</router-link>
					</template>
					<template v-slot:item.is_active="{item}">
						<span v-if="item.is_active == 1">Опубликовано</span>
						<span v-else>Скрыто</span>
					</template>
				</datatable>
			</v-card-text>
        </v-card>
    </div>
</template>

<script>
import InputText from '@/components/base/inputText'
import Datatable from '@/components/base/datatable'

export default {
    components: {
        InputText,
        Datatable
    },
    data: () => ({
		lang: process.env.VUE_APP_LANG,
        datatableheaders: [
            { text: 'Название', value: 'title' },
            { text: 'Дата публикации', value: 'created_at' },
            { text: 'Статус', value: 'is_active' },
        ],
        datatabledata: [],
		search: null,
    }),
    mounted(){
        this.getItems();
    },
    methods: {
        getItems(){
            this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'v1/system/blogs').then((response) => {
                this.datatabledata = response.data;
				this.datatabledata.map(item => {
					item.created_at = this.formatDate(item.created_at);
					return item;
				})
            })
        },
		formatDate(date){
			var proces = new Date(date);
			return proces.getDate() + '/' + proces.getMonth() + '/' + proces.getFullYear() + ' ' + proces.getHours() + ':' + proces.getMinutes();
		}
    }
}
</script>
