<template>
	<div>
		<v-card class="elevation-0">
			<v-card-title>Основная информация — {{item.title}}</v-card-title>
		</v-card>
		<v-row>
			<v-col cols="12" sm="4">
				<v-card class="elevation-0 pa-6 mt-5">
					<v-card-text >
						<v-row>
							<v-col cols="12" >
								<div class="font-16-60 black-dark--text">Title:</div>
								<v-text-field v-model="item.title" hide-details></v-text-field>
							</v-col>
							<v-col cols="12" >
								<div class="font-16-60 black-dark--text">IDNO:</div>
								<v-text-field v-model="item.idno" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">City:</div>
								<v-text-field v-model="item.city" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">Website:</div>
								<v-text-field v-model="item.website" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">Email:</div>
								<v-text-field v-model="item.email" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">Category:</div>
								<v-select v-model="item.id_category" :items="categories" item-text="category" item-value="id" hide-details></v-select>
							</v-col>
						</v-row>
						<buton @click="saveItem()" class="bg-green font-16-60 white--text mt-8" x-large>Save</buton>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="8">
				<v-row>
					<v-col cols="12" sm="6">
						<v-card class="elevation-0 pa-6 mt-5">
							<v-card-text >
								<div class="font-16-60 black-dark--text">Phone Numbers:</div>
								<div v-if="item.contacts.length > 0" class="mt-5">
									<div class="font-14-60 black-dark--text">Personal Phone:</div>
									<v-simple-table dense class="">
										<template v-slot:default>
											<tbody v-for="(item, key) in item.contacts" :key="key">
												<tr>
													<td>
														<div>
															<v-text-field v-model="item.name" label="Name"></v-text-field>
														</div>
														<div>
															<v-text-field v-model="item.phone" label="Phone" prepend-inner-icon="mdi-plus"></v-text-field>
														</div>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
									<div class="d-flex align-center justify-center">
										<div class="">
											<v-btn color="green" icon @click="pushContact()"><v-icon >add</v-icon></v-btn>
										</div>
									</div>
								</div>
								<div class="mt-5">
									<div class="font-14-60 black-dark--text">Office:</div>
									<v-simple-table dense class="customsimpledatatable">
										<template v-slot:default>
											<tbody>
												<tr
													v-for="(element, key) in item.phones"
													:key="key"
												>
													<td>
														<v-text-field v-model="element.phone" label="Phone"></v-text-field>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
									<div class="d-flex align-center justify-center">
										<div class="">
											<v-btn color="green" icon @click="pushPhone()"><v-icon >add</v-icon></v-btn>
										</div>
									</div>
								</div>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" sm="6">
						<v-card class="elevation-0 pa-6 mt-5">
							<v-card-text >
								<div class="font-16-60 black-dark--text">Description:</div>
								<v-textarea v-model="item.description" rows="10"></v-textarea>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
// import Card from '@/components/base/card'
// import Datatable from '@/components/base/datatable'
import Buton from '@/components/base/buton'

export default {
    components: {
        // Card,
        Buton,
		// Datatable
    },
    data: () => ({
        item: {
			contacts: [],
			phones: [],
		},
		categories: [],
        phones: [],
		callsheaders: [
            { text: 'Number', value: 'number' },
			{ text: 'Operator', value: 'account' },
            { text: 'Duration (sec)', value: 'duration' },
			{ text: 'CallTime', value: 'start' },
            { text: 'Actions', align: 'end', value: 'actions' },
        ],
		calldialog: false,
		currentItem: null
    }),
    mounted(){
		this.pushContact();
		this.pushPhone();
        this.getCategories();
		this.checkPhone();
    },
    methods: {
		getCategories(){
			this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'category').then((response) => {
                this.categories = response.data;
            })
		},
		pushContact(){
			this.item.contacts.push({name: null,phone: null});
		},
		pushPhone(){
			this.item.phones.push({phone: null});
		},
        saveItem(){
			this.concatPhones();
            this.axios.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'customer/', this.item)
        },
		concatPhones(){
			this.item.phone = [];
			this.item.phones.map(item => {
				this.item.phone.push(item.phone);
			})
			this.item.phone = this.item.phone.join();
		},
		checkPhone(){
			if(!this.$route.params.phone)
				return;
			this.item.contacts[0].phone = this.$route.params.phone
		}
    }

}
</script>
<style>
.customsimpledatatable .v-data-table__wrapper tbody tr td{
	height:44px !important;
}
</style>
