<template>
    <div class="px-10 py-8 h-100" style="border-radius: 10px;">
        <router-view/>
    </div>
</template>

<script>
export default {
}
</script>
