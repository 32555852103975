<template>
    <v-data-table
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template v-for="name in itemSlots" v-slot:[name]="{ item }">
            <slot :name="name" :item="item"></slot>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: ['itemSlots'],

}
</script>
