import Login from '@/pages/auth/login'

import Clients from '@/pages/clients/index'
import Client from '@/pages/clients/single'
import NewClient from '@/pages/clients/new'

import Categories from '@/pages/categories/index'
import Statuses from '@/pages/statuses/index'

import Calls from '@/pages/calls/index'

import Users from '@/pages/users/index'

import Tasks from '@/pages/tasks/index'

import Search from '@/pages/search/index'


import Blogs from '@/pages/blogs/index'
import Blog from '@/pages/blogs/make'

const routes = [

    {
        path: '/clients',
        name: 'clients',
        component: Clients,
		meta: {
			authOnly: true,
			title: 'Clients'
		},
    },
	{
        path: '/clients/category/:id',
        name: 'clients-category',
        component: Clients,
		meta: {
			authOnly: true,
			title: 'Clients By Category'
		},
    },
	{
        path: '/clients/status/:id',
        name: 'clients-status',
        component: Clients,
		meta: {
			authOnly: true,
			title: 'Clients By Status'
		},
    },
    {
        path: '/client/new/:phone?',
        name: 'newclient',
        component: NewClient,
		meta: {
			authOnly: true,
			title: 'Client'
		},
    },
    {
        path: '/client/:id',
        name: 'client',
        component: Client,
		meta: {
			authOnly: true,
			title: 'Client'
		},
    },
	{
        path: '/categories',
        name: 'categories',
        component: Categories,
		// meta: {
		// 	authOnly: true,
		// 	title: 'Заявки'
		// },
    },
	{
        path: '/search',
        name: 'search',
        component: Search,

    },
    {
        path: '/statuses',
        name: 'statuses',
        component: Statuses,
		// meta: {
		// 	authOnly: true,
		// 	title: 'Заявки'
		// },
    },
	{
        path: '/calls',
        name: 'calls',
        component: Calls,
		// meta: {
		// 	authOnly: true,
		// 	title: 'Заявки'
		// },
    },
	{
        path: '/users',
        name: 'users',
        component: Users,
		meta: {
			authOnly: true,
			title: 'Пользователи'
		},
    },

	{
        path: '/blogs',
        name: 'blogs',
        component: Blogs,
		meta: {
			// authOnly: true,
			title: 'Новости'
		},
    },
	{
        path: '/blog/:id?',
        name: 'blog',
        component: Blog,
		meta: {
			// authOnly: true,
			title: 'Новости'
		},
    },
	{
        path: '/tasks',
        name: 'tasks',
        component: Tasks,
		meta: {
			// authOnly: true,
			title: ''
		},
    },
    {
        path: '/auth/login',
        name: 'login',
        component: Login,
		meta: { guestOnly: true }
    },

]

export default routes
