<template>
    <v-text-field
        v-bind="$attrs"
        v-on="$listeners"
		solo
		flat
		class=""
		hide-details
		:ripple="false"
    >
        <slot/>
    </v-text-field>
</template>

<script>
export default {

}
</script>
<style lang="scss">
.textfield-custom2{
	.v-input__control{
		.v-input__slot{
			padding-top: 12px !important;
			padding-bottom: 12px !important;
			background: #E4EBF5!important;

		}
		.v-input__slot::before{
			content:none;
		}
	}
}
</style>
