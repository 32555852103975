	<template>
	<div class="">
		<v-row class="fill-height">
			<v-col>
				<v-sheet height="64">
					<v-toolbar
						flat
					>
						<v-btn
							outlined
							class="mr-4"
							color="grey darken-2"
							@click="setToday"
						>
							Today
						</v-btn>
						<v-btn
							fab
							text
							small
							color="grey darken-2"
							@click="prev"
						>
							<v-icon small>
								mdi-chevron-left
							</v-icon>
						</v-btn>
						<v-btn
							fab
							text
							small
							color="grey darken-2"
							@click="next"
						>
							<v-icon small>
								mdi-chevron-right
							</v-icon>
						</v-btn>
						<v-toolbar-title v-if="$refs.calendar">
							{{ $refs.calendar.title }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-menu
							bottom
							right
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									outlined
									color="grey darken-2"
									v-bind="attrs"
									v-on="on"
								>
									<span>{{ typeToLabel[type] }}</span>
									<v-icon right>
										mdi-menu-down
									</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item @click="type = 'day'">
									<v-list-item-title>Day</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'week'">
									<v-list-item-title>Week</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'month'">
									<v-list-item-title>Month</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = '4day'">
									<v-list-item-title>4 days</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar>
				</v-sheet>
				<v-sheet height="800">
					<v-calendar
						ref="calendar"
						v-model="focus"
						color="primary"
						:events="events"
						:event-color="getEventColor"
						:type="type"
						locale="ru"
						first-interval="8"
						interval-count="16"
						interval-height="90"
						@click:event="showEvent"
						@click:more="viewDay"
						@click:date="viewDay"
						@change="updateRange"
						event-name="category"
					></v-calendar>
					<v-menu
						v-model="selectedOpen"
						:close-on-content-click="false"
						:activator="selectedElement"
						offset-x
					>
						<v-card
							color="grey lighten-4"
							min-width="350px"
							flat
						>
							<v-toolbar
								:color="selectedEvent.color"
								dark
							>
								<!-- <v-btn icon>
									<v-icon>mdi-pencil</v-icon>
								</v-btn> -->
								<v-toolbar-title>{{selectedEvent.category}} - {{selectedEvent.name}}</v-toolbar-title>
								<v-spacer></v-spacer>
							</v-toolbar>
							<v-card-text>
								<span v-html="selectedEvent.description"></span>
								<div v-if="selectedEvent.customer" class="">
									<b>Customer Info</b>
									<p>Title: <router-link :to="{ name: 'client', params: {id: selectedEvent.customer.id} }" v-html="selectedEvent.customer.title"></router-link></p>
									<p v-if="selectedEvent.executor">Executor: <router-link :to="{ name: 'client', params: {id: selectedEvent.executor.id} }" v-html="selectedEvent.executor.name"></router-link></p>

								</div>
							</v-card-text>
							<v-card-actions>
								<v-btn
									v-if="selectedEvent.id_status != 10"
									text
									color="secondary"
									@click="selectedOpen = false"
								>
									Cancel
								</v-btn>
								<v-btn
									:disabled="selectedEvent.id_status == 10"
									text
									color="success"
									@click="changeStatus(10)"
								>
									Executed
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-sheet>
			</v-col>
		</v-row>
	</div>
</template>

<script>


export default {
	components: {
	},
	data: () => ({
		focus: '',
		type: 'month',
		typeToLabel: {
			month: 'Month',
			week: 'Week',
			day: 'Day',
			'4day': '4 Days',
		},
		form: {},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'orange', 'grey darken-1'],
	}),
	mounted () {
		this.$refs.calendar.checkChange()
	},
	methods: {
		viewDay ({ date }) {
			this.focus = date
			this.type = 'day'
		},
		getEventColor (event) {
			return event.color
		},
		setToday () {
			this.focus = ''
		},
		prev () {
			this.$refs.calendar.prev()
		},
		next () {
			this.$refs.calendar.next()
		},
		changeStatus(status){
			const request = {
				id: this.selectedEvent.id,
				id_status: status
			}
			this.axios.put(process.env.VUE_APP_AXIOS_API_DOMAIN + 'tasks/' + this.selectedEvent.id, request).then(() => {
				this.selectedOpen = false;
			})
		},
		showEvent ({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event
				this.selectedElement = nativeEvent.target
				requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
			}

			if (this.selectedOpen) {
				this.selectedOpen = false
				requestAnimationFrame(() => requestAnimationFrame(() => open()))
			} else {
				open()
			}

			nativeEvent.stopPropagation()
		},
		updateRange ({ start, end }) {
			// const events = []

			const params = {
				date:{
					start: start.date,
					end: end.date
				}
			}

			this.axios.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'tasks/', params).then(response => {
				this.events = response.data.map(item => {
					item.color = this.colors[this.rnd(0, this.colors.length - 1)];
					if(item.id_status == 10)
						item.color = 'green';
					return item;
				})
			})

			// const min = new Date(`${start.date}T00:00:00`)
			// const max = new Date(`${end.date}T23:59:59`)
			// const days = (max.getTime() - min.getTime()) / 86400000
			// const eventCount = this.rnd(days, days + 20)
			//
			// for (let i = 0; i < eventCount; i++) {
			// 	const allDay = this.rnd(0, 3) === 0
			// 	const firstTimestamp = this.rnd(min.getTime(), max.getTime())
			// 	const first = new Date(firstTimestamp - (firstTimestamp % 900000))
			// 	const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
			// 	const second = new Date(first.getTime() + secondTimestamp)
			//
			// 	events.push({
			// 		name: this.names[this.rnd(0, this.names.length - 1)],
			// 		start: first,
			// 		end: second,
			// 		color: this.colors[this.rnd(0, this.colors.length - 1)],
			// 		timed: !allDay,
			// 	})
			// }
			//
			// this.events = events
		},
		rnd (a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a
		},
	},
}
</script>
