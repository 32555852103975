<template>
	<v-row>
		<v-col cols="12" lg="7">
			<v-card class="elevation-0">
				<v-card-title>Основная информация</v-card-title>
				<v-card-text class="px-10">
					<div>
						<div>
							<div class="font-16-60 black-dark--text">Видимость</div>
							<v-radio-group v-model="item.is_active" row>
								<v-radio label="Показывать" :value="1"></v-radio>
								<v-radio label="Скрыть" :value="0"></v-radio>
							</v-radio-group>
						</div>
						<div>
							<div class="font-16-60 black-dark--text">Статья</div>
							<div v-if="item.content">
								<text-input v-model="item.content.title" filled class="textfield-custom mt-2 mb-5" placeholder="Название" />
								<text-input v-model="item.content.description" filled class="textfield-custom mb-5" placeholder="Описание" />
								<vue-editor
									v-model="item.content.content"
									placeholder="Статья"
								></vue-editor>
							</div>
						</div>
						<div class="mt-4">
							<img v-if="item.image" :src="getImage(item.image)" width="200" class="mb-4">
							<v-file-input v-model="item.image" label="Добавить превью" class="mb-4"></v-file-input>
							<img v-if="item.background" :src="getImage(item.background)" width="200" class="mb-4">
							<v-file-input v-model="item.background" label="Добавить бэкграунд" class="mb-4"></v-file-input>
						</div>
					</div>
				</v-card-text>
				<v-card-actions class="px-10 justify-end pb-4">
					<div class="">
						<buton :to="{name: 'blogs'}"  class="bg-grey mr-3" x-large>Отменить</buton>
						<buton @click="submit()" class="bg-green font-16-60 white--text" x-large>Опубликовать</buton>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { serialize } from 'object-to-formdata';
import Buton from '@/components/base/buton'

import TextInput from '@/components/base/inputText'

export default {
	components: {
		TextInput,
		Buton,
		VueEditor
	},
	data: () => ({
		tab: 'en',
		item: {
			is_active: 1,
			content: {

			}
		}
	}),
	mounted(){
		this.initItem();
		if(this.$route.params.id){
			this.getItm()
		}

	},
	methods: {
		initItem(){
			this.$store.state.breadCrumbs = [
				{
					to: '/vacancies', // hyperlink
					text: 'Управление вакансиями' // crumb text
				},
				{
					text: this.$route.params.id ? 'Редактировать статью' : 'Добавить новую статью',
					disabled: true
				}
			]
			// for(const item in this.$store.state.languages){
			// 	this.item.content[item] = {lang: item};
			// }
		},
		submit(){
			var uri = process.env.VUE_APP_AXIOS_API_DOMAIN + 'v1/system/blogs/';

			if(this.$route.params.id){
				uri += this.$route.params.id;
				this.item._method = 'PUT';
			}

			this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
            // let formData = new FormData();
            const formData = serialize(
                this.item,
                {
                    indices: true,
                    allowEmptyArrays: true
                }
            );

			this.axios.post(uri, formData).then(response => {
				if(response.status == 200 || response.status == 201)
					this.$router.push({name: 'blogs'});
			})
		},
		getItm(){
			this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'v1/system/blogs/' + this.$route.params.id).then(response => {
				this.item = response.data
			})
		},

		updateItem(){
			this.axios.put(process.env.VUE_APP_AXIOS_API_DOMAIN + 'v1/system/blogs/' + this.$route.params.id, this.item).then(response => {
				if(response.status == 200)
					this.$router.push({name: 'blogs'});
			})
		},

		getImage(image){
			return process.env.VUE_APP_BACKEND_DOMAIN + image;
		}
	}

}
</script>
