<template>
    <div class="">
        <v-btn
            class="mb-3"
            dark
            color="primary"
            @click="initEditor({})"
        >
            <v-icon dark>
                add
            </v-icon>
            Добавить
        </v-btn>
        <card class="elevation-0">
            <datatable
                :headers="datatableheaders"
                :items="datatabledata"
				:itemSlots="['item.actions', 'item.role']"
            >
				<template v-slot:item.role="{item}">
					<div class="" v-html="item.is_admin ? 'Admin' : 'Manager'"></div>
				</template>
				<template v-slot:item.actions="{item}">
					<v-icon @click="initEditor(item)">edit</v-icon>
				</template>
            </datatable>
        </card>
        <v-dialog
            v-model="makeDialog"
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5" v-html="activeItem.category ? 'Edit ' + activeItem.category : 'New Item'"></v-card-title>

                <v-card-text>
					<input-text v-model="activeItem.name" label="Name" class="my-3"/>
					<input-text v-model="activeItem.email" label="Email" class="my-3"/>
					<input-text v-model="activeItem.password" label="Password" type="password" class="my-3"/>
					<input-select v-model="activeItem.moldcell_ext" @change="selectUser" :items="moldcellUsers" item-text="name" item-value="ext" label="Moldcell User" class="my-3" return-object/>
					<v-checkbox v-model="activeItem.is_admin" label="Is Admin"></v-checkbox>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                    <v-btn
                        color="danger darken-1"
                        text
                        @click="activeItem = false; makeDialog = false;"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="saveItem()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InputText from '@/components/base/inputText'
import InputSelect from '@/components/base/inputSelect'
import Card from '@/components/base/card'
import Datatable from '@/components/base/datatable'

export default {
    components: {
		InputSelect,
        InputText,
        Card,
        Datatable
    },
    data: () => ({
		lang: process.env.VUE_APP_LANG,
		datatableheaders: [
            { text: 'ID', value: 'id' },
			{ text: 'Имя', value: 'name' },
			{ text: 'Email', value: 'email' },
            { text: 'Роль', value: 'role' },
            { text: 'Actions', value: 'actions' },
        ],
        datatabledata: [],
        activeItem: false,
        makeDialog: false,
		moldcellUsers: []
    }),
    mounted(){
        this.getItems();
    },
    methods: {
        getItems(){
            this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'user').then((response) => {
                this.datatabledata = response.data;
            })
        },
        saveItem(){
            var url = process.env.VUE_APP_AXIOS_API_DOMAIN + 'user';

            if(this.activeItem.id){
                this.activeItem['_method'] = 'PUT';
                url = url + '/' + this.activeItem.id;
            }

            this.axios.post(url, this.activeItem).then((response) => {
                if(!this.activeItem.id)
                    this.datatabledata.push(response.data);

                this.activeItem = false;
                this.makeDialog = false;

            })
        },
		selectUser(item){
			// console.log(val);
			this.activeItem.moldcell_name = item.name
			this.activeItem.moldcell_telnum = item.telnum
			this.activeItem.moldcell_ext = item.ext
		},
		initEditor(data){
			this.activeItem = data;
			this.makeDialog = true;
			if(this.moldcellUsers.length < 1)
				this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'moldcell/accounts').then(response => {
					this.moldcellUsers = response.data
				})

		}
    }
}
</script>
