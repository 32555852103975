import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

import './sass/main.scss'

Vue.config.productionTip = false
axios.defaults.withCredentials = true

new Vue({
	vuetify,
	router,
	store,
	created () {
		const userInfo = localStorage.getItem('user')
		if (userInfo) {
			const userData = JSON.parse(userInfo)
			this.$store.commit('auth/setUserData', userData)
		}
		axios.interceptors.response.use(
			response => response,
			error => {
				if (error.response.status === 401) {
					this.$store.dispatch('auth/logout')
				}
				return Promise.reject(error)
			}
		)
	},

	render: h => h(App)
}).$mount('#app')
