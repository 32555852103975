import axios from 'axios'

export default {
	namespaced: true,

	state: {
		dialog: false,
		form: {
			id_status: 1,
		}
	},

	mutations: {
		closeDialog (state) {
			state.dialog = false;
		},
		clearData (state) {
			state.form = {
				id_status: 1
			}
		}
	},

	actions: {
		make ({ commit, state }, user) {
			state.form.id_user = user;

			axios
				.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'tasks/store', state.form)
				.then(() => {
					commit('clearData')
					commit('closeDialog')
				})
		},
		clear ({ commit }) {
			commit('clearData')
		},
		close ({ commit }) {
			commit('closeDialog')
		}
	},
}
