<template>
    <div class="">
        <div class="d-flex">
			<buton
				color="primary"
				x-large
				class="mr-5"
				:to="{name: 'newclient'}"
			>
				<v-icon dark>add</v-icon> Добавить
			</buton>
            <input-text
				v-model="search"
				append-icon="search"
				class="textfield-custom2"
			/>
        </div>
        <v-card class="elevation-0 mt-10">
			<v-card-title v-if="$route.name.substr(8)">
				<div class="">
					<div class="">
						Only {{$route.name.substr(8)}} - {{section}}
					</div>
				</div>
			</v-card-title>
			<v-card-text class="px-6" v-if="filteredItems()">
				<datatable
					:headers="datatableheaders"
					:items="filteredItems()"
					:itemSlots="['item.title', 'item.website', 'item.operator']"
					:search="search"
				>
					<!-- <template v-slot:item.type="{ item }">
						<router-link :to="{ name: 'request', params: {id: item.id} }" v-html="item.type"></router-link>
					</template> -->
                    <template v-slot:item.title="{item}">
						<router-link :to="{name: 'client', params: {id: item.id}}" v-html="item.title"></router-link>
						<!-- <v-btn icon depressed fab :to="{name: 'client', params: {id: item.id}}">
							<v-icon color="primary">visibility</v-icon>
						</v-btn> -->
                    </template>
					<template v-slot:item.website="{item}">
						<a :href="'https://' + item.website" target="_blank" v-html="item.website"></a>
					</template>
					<template v-slot:item.operator="{item}">
						<router-link v-if="item.last_call" :to="{name: 'users', params: {id: item.last_call.user.id}}" v-html="item.last_call.user.name"></router-link>
                    </template>
				</datatable>
			</v-card-text>
        </v-card>
    </div>
</template>

<script>
import InputText from '@/components/base/inputText'
import Datatable from '@/components/base/datatable'
import Cookie from '@/mixins/CoockieMixin'
import Buton from '@/components/base/buton'

export default {
    components: {
        InputText,
        Datatable,
		Buton
    },
    data: () => ({
        datatableheaders: [
			{ text: 'Name', value: 'title' },
			{ text: 'IDNO', value: 'idno' },
            { text: 'Website', value: 'website' },
            { text: 'City', value: 'city' },
			{text: 'Operator', value: 'operator'}
            // { text: 'Имя', value: 'name' },
            // { text: 'Телефон', value: 'phone' },
            // { text: 'Email', value: 'mail' },
            // { text: 'Actions', value: 'actions' },
        ],
        datatabledata: null,
		group: null,
		search: null,
		section: null,

    }),
    mounted(){
        this.getItems();
    },
	mixins: [
		Cookie
	],
    methods: {
        async getItems(){
			// await this.getCookie();
			var uri = process.env.VUE_APP_AXIOS_API_DOMAIN + 'customer/';
			if(this.$route.name.substr(8))
				uri += this.$route.name.substr(8) + '/' + this.$route.params.id;

			if(this.$route.name.substr(8) == 'category')
				return this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'category/' + this.$route.params.id).then((response) => {
					this.section = response.data.category;
					this.datatabledata = response.data.customers;
					this.datatabledata.map(item => {
						item.created_at = this.formatDate(item.created_at);
						return item;
					})
				})

            this.axios.get(uri).then((response) => {
                this.datatabledata = response.data;
				this.datatabledata.map(item => {
					item.created_at = this.formatDate(item.created_at);
					return item;
				})
            })
        },
		formatDate(date){
			var proces = new Date(date);
			return proces.getDate() + '/' + proces.getMonth() + '/' + proces.getFullYear() + ' ' + proces.getHours() + ':' + proces.getMinutes();
		},
		filteredItems(){
			if(this.group != null)
				return this.datatabledata.filter(item => {
					return item.id_status == this.group
				})
			return this.datatabledata
		},
		filterCategory(){
			this.datatabledata.filter(item => {
				return item.id_status == this.group
			})
		}
    }
}
</script>
