import Cookie from "js-cookie";
import axios from "axios";

export default {
	methods: {
		getCookie() {
			let token = Cookie.get("XSRF-TOKEN");

			if (token) {
				return new Promise(resolve => {
					resolve(token);
				});
			}
			axios.defaults.withCredentials = true;
			return axios.get(process.env.VUE_APP_BACKEND_DOMAIN + "/sanctum/csrf-cookie");
		}
	}
}
