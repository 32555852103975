import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import * as Cookies from 'js-cookie'
Vue.use(Vuex)

import auth from './modules/auth'
import task from './modules/task'
import config from './modules/config'

const store = new Vuex.Store({
	state: () => ({
		languages: {ru: 'RUS', en: 'ENG', ro: 'RO'},
		breadCrumbs: [],
	}),
    modules: {
		auth,
		task,
		config
    // b: moduleB
	},
	plugins: [createPersistedState()]

})

export default store;
