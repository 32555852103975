<template>
	<v-dialog
		v-model="$store.state.config.caller.dialog"
		width="400"
	>
		<v-card>
			<v-card-title>Make Call</v-card-title>
			<v-card-text>
				<div class="">
					<div class="">
						<v-text-field v-model="number" label="Phone Number"></v-text-field>
					</div>
					<v-row>
						<v-col cols="4" v-for="item in list" :key="item">
							<div class="text-center" v-if="item == '+'">
								<v-btn @click="add('+')">+</v-btn>
							</div>
							<div class="text-center" v-else-if="item == 'backspace'">
								<v-btn @click="remove()"><v-icon>backspace</v-icon></v-btn>
							</div>
							<div class="text-center" v-else>
								<v-btn @click="add(item)">{{item}}</v-btn>
							</div>
						</v-col>
					</v-row>
					<div class="mt-5 text-center">
						<v-btn class="success" dark @click="call()">Call</v-btn>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data: () => ({
		list: [1, 2, 3, 4, 5, 6, 7, 8, 9, '+', 0, 'backspace'],
		number: '+373'
	}),
	methods:{
		add(data){
			this.number += data
		},
		remove(){
			this.number = this.number.slice(0, -1);
		},
		call(){
			var data = { phone: this.number, user: this.$store.state.auth.user.moldcell_ext};
			this.axios.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'moldcell/call', data).then(() => {
				this.$store.state.config.caller.dialog = false;
			})
		},
	}
}
</script>

<style lang="css" scoped>
</style>
