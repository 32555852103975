<template>
    <div class="">
        <div class="d-flex">

        </div>
        <v-card class="elevation-0 mt-10">
			<v-card-text class="px-6">
				<div class="d-flex justify-space-between">
					<div class="flex-grow-1" v-if="$store.state.auth.user.is_admin">
						<v-select v-model="filter.account" @change="getFiltered()" label="Operator" :items="users" item-text="name" item-value="id" clearable></v-select>
					</div>
					<div class="px-5 flex-grow-1">
						<v-menu
							ref="datemenu"
							v-model="datemenu"
							:close-on-content-click="false"
							:return-value.sync="filter.date"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="filter.date"
									label="Date"
									readonly
									v-bind="attrs"
									v-on="on"
									clearable
								></v-text-field>
							</template>
							<v-date-picker
								v-model="filter.date"
								no-title
								scrollable
								range
							>
							<v-spacer></v-spacer>
								<v-btn
									text
									color="primary"
									@click="datemenu = false"
								>
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.datemenu.save(filter.date); getFiltered()"
								>
									OK
								</v-btn>
							</v-date-picker>
						</v-menu>
					</div>
					<div class="flex-grow-1">
						<v-text-field
							v-model="filter.search"
							label="Company name or Phone"
							append-icon="search"
						></v-text-field>
						<!-- @change="getFiltered()" -->
					</div>
				</div>
				<!-- :search="search" -->
				<v-data-table
					:headers="datatableheaders"
					:items="datatabledata"
					:loading="loading"
					loader-height="2"
					show-expand
					:items-per-page="(page + 1) * 100"
					v-if="datatabledata"
				>
					<template v-slot:expanded-item="{ item }">
						<td :colspan="6" v-if="item.record">
							<audio controls class="w-100">
								<source :src="item.record" type="audio/mp3" />
							</audio>
						</td>
					</template>
					<template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
						<v-icon v-if="item.record" :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]" @click.stop="expand(!isExpanded)">$expand</v-icon>
					</template>
					<template v-slot:item.client_number="{ item }">
						<div class="">
							<div class="">
								<v-icon v-if="item.type == 'out'" small color="green">call_made</v-icon>
								<v-icon v-else-if="item.type == 'in'" small color="blue">call_received</v-icon>
								<v-icon v-else small color="red">call_missed</v-icon>
								{{item.client_number}}
							</div>
							<div class="">
								<router-link v-if="item.id_client" :to="{ name: 'client', params: {id: item.id_client} }" v-html="item.client.title"></router-link>
							</div>
						</div>
					</template>
					<template v-slot:item.account="{ item }">
						<div class="">
							<div class="" v-if="item.id_user">
								<router-link :to="{ name: 'user-page', params: {id: item.id_user} }" v-html="item.user.name"></router-link>
							</div>
							<div v-else v-html="item.account">

							</div>
						</div>
					</template>
					<template v-slot:item.duration="{ item }">
						<div class="">
							{{Math.floor(item.duration / 60) + " min " + (item.duration % 60 ? item.duration % 60 : '00')}} sec
						</div>
					</template>
					<template v-slot:item.operator="{ item }">
						<div class="">
							<div class="">
								<router-link :to="{ name: 'client', params: {id: item.id_user} }" v-html="item.id_user ? item.user.name : item.account"></router-link>
							</div>
						</div>
					</template>
                    <template v-slot:item.actions="{item}">
						<div class="d-flex justify-end">
							<v-btn v-if="!item.id_client" fab icon :to="{name: 'newclient', params: {phone: item.client_number}}" class="text-decoration-none"><v-icon>person_add</v-icon></v-btn>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn fab icon color="blue" v-bind="attrs" v-on="on" @click="currentItem = item; dialog = true;">
										<v-icon>{{item.note ? 'textsms' : 'add_comment'}}</v-icon>
									</v-btn>
								</template>
								<span>{{item.note}}</span>
							</v-tooltip>
							<v-btn color="success" @click="call(item.client_number)" fab icon><v-icon color="success">phone</v-icon></v-btn>
							<v-btn color="black" @click="addEvent(item)" fab icon><v-icon color="black">event</v-icon></v-btn>
						</div>
                    </template>
					<template v-slot:footer>
						<div v-observe-visibility="{ callback: isViewableNow }"></div>
					</template>
				</v-data-table>
			</v-card-text>
        </v-card>
		<v-dialog v-model="dialog" max-width="500">
			<v-card v-if="currentItem">
				<v-card-title>
					Call Info
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6">
							<div v-if="currentItem.id_client" class="font-16-60 my-3">
								Client: {{currentItem.client.title}}
							</div>
							<div class="font-16-60 my-3">
								Phone: {{currentItem.client_number}}
							</div>
							<div class="font-16-60 my-3">
								Start Call: {{currentItem.start}}
							</div>
							<div class="font-16-60 my-3">
								Wait: {{currentItem.wait}} sec
							</div>
						</v-col>
						<v-col cols="6">
							<div v-if="currentItem.id_user" class="font-16-60 my-3">
								Client: {{currentItem.user.name}}
							</div>
							<div class="font-16-60 my-3">
								Type of call: {{currentItem.type}}
							</div>
							<div class="font-16-60 my-3">
								Duration Call: {{Math.floor(currentItem.duration / 60) + " min " + (currentItem.duration % 60 ? currentItem.duration % 60 : '00')}} sec
							</div>
							<div v-if="currentItem.record" class="font-16-60 my-3">
								Record: <a :href="currentItem.record">Get Record</a>
							</div>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="currentItem.note" label="Note"></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red" dark @click="dialog = false; currentItem = null">
						Cancel
					</v-btn>
					<v-btn color="green" dark @click="updateCall()">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" color="success" top right>{{snackbartext}}</v-snackbar>
    </div>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility';

// import InputText from '@/components/base/inputText'
// import Datatable from '@/components/base/datatable'
import Cookie from '@/mixins/CoockieMixin'
import SnackbarMixin from '@/mixins/snakbarMixin'
export default {
    components: {
        // InputText,
        // Datatable
    },
	directives: {
        ObserveVisibility
    },
    data: () => ({
        datatableheaders: [
			{ text: 'Number', value: 'client_number' },
            { text: '', value: 'client.title', class: 'd-none', cellClass: 'd-none' },
			{ text: 'Operator', value: 'account' },
            { text: 'Duration (sec)', value: 'duration' },
			{ text: 'CallTime', value: 'start' },
            { text: 'Actions', align: 'end', value: 'actions' },
        ],
        datatabledata: [],
		dialog: false,
		currentItem: null,
		loading: true,
		lock: true,
		page: 0,
		users: [],
		filter: {},
		datemenu: false

    }),
    mounted(){
        this.getItems();
		this.getUsers();
    },
	mixins: [
		Cookie,
		SnackbarMixin
	],
	watch: {
		'filter.search':  function () {
			this.getFiltered();
		},
	},
    methods: {
        getItems(clear = false){
			this.axios.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'calls/' + this.page, this.filter).then((response) => {
				if(clear)
					this.datatabledata = [];

				this.datatabledata = this.datatabledata.concat(response.data);
				this.loading = false;
				this.lock = false;
				if(response.data.length < 100)
					this.lock = true;
            })
        },
		getUsers(){
			this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'user').then((response) => {
				this.users = response.data;
			})
		},
		updateCall(){
			this.axios.put(process.env.VUE_APP_AXIOS_API_DOMAIN + 'calls/' + this.currentItem.id, this.currentItem).then(() => {
				this.dialog = false;
				this.currentItem = null;
				this.snackbar = true;
				this.snackbartext = 'The call is updated';
			})
		},
		call(phone){
			var data = { phone: phone, user: this.$store.state.auth.user.moldcell_ext};
			this.axios.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'moldcell/call', data).then(() => {
				this.dialog = false;
				this.currentItem = null;
				this.snackbar = true;
				this.snackbartext = 'Phone number ' + phone + ' is called now';
			})
		},
		isViewableNow() {
			if(this.lock == false){
				this.page ++;
				this.lock = true;
				this.loading = true;
				this.getItems();
			}
        },
		getFiltered(){
			this.page = 0;
			this.getItems(true);
		},
		addEvent(item){
			this.$store.state.task.dialog = true;
			this.$store.state.task.form.id_category = 1;
			this.$store.state.task.form.name = item.client_number;
			this.$store.state.task.form.assing_to = item.id_user;
			this.$store.state.task.form.id_customer = item.id_client;
		}
    }
}
</script>
