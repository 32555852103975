<template>
	<div v-if="item">
		<div class="d-flex justify-space-between">
			<div class="">
				<buton text class="font-16-60 remove-before px-0" x-large depressed v-if="item.id - 1 > 0 " :to="{name: 'client', params: {id: item.id - 1}}">
					<v-icon>arrow_back</v-icon> Prev
				</buton>
			</div>
			<buton text class="font-16-60 remove-before px-0" x-large depressed :to="{name: 'client', params: {id: item.id + 1}}">
				Next <v-icon>arrow_forward</v-icon>
			</buton>
		</div>
		<v-card class="elevation-0">
			<v-card-title class="justify-space-between">
				<div class="">
					Основная информация — {{item.title}}
				</div>
				<v-btn color="black" @click="addCompanyEvent(item)" fab icon><v-icon color="black">event</v-icon></v-btn>
			</v-card-title>
		</v-card>
		<v-row>
			<v-col cols="12" sm="4">
				<v-card class="elevation-0 pa-6 mt-5">
					<v-card-text >
						<v-row>
							<v-col cols="12" >
								<div class="font-16-60 black-dark--text">Title:</div>
								<v-text-field v-model="item.title" hide-details></v-text-field>
							</v-col>
							<v-col cols="12" >
								<div class="font-16-60 black-dark--text">IDNO:</div>
								<v-text-field v-model="item.idno" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">City:</div>
								<v-text-field v-model="item.city" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">Website:</div>
								<v-text-field v-model="item.website" hide-details append-icon="open_in_new" @click:append="goToSite(item.website)"></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">Email:</div>
								<v-text-field v-model="item.email" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">Category:</div>
								<v-select v-model="item.id_category" :items="categories" item-text="category" item-value="id" hide-details></v-select>
							</v-col>
							<v-col cols="12">
								<div class="font-16-60 black-dark--text">Status:</div>
								<v-combobox
									v-model="item.statuses"
									:items="statuses"
									chips
									persistent-hint
									multiple
									hide-selected
									item-text="name"
									item-value="id_status"
								>
									<template v-slot:selection="{ attrs, item, parent, selected }">
										<v-chip
											v-if="item === Object(item)"
											v-bind="attrs"
											:input-value="selected"
											label
										>
											<span class="pr-2">
												{{ item.name }}
											</span>
											<v-icon small @click="parent.selectItem(item); removeStatus(item)">$delete</v-icon>
										</v-chip>
									</template>
								</v-combobox>
								<!-- <v-select v-model="item.statuses" :items="statuses" item-text="name" item-value="id" multiple hide-details return-object></v-select> -->
							</v-col>
						</v-row>
						<buton @click="updateItem()" class="bg-green font-16-60 white--text mt-8" x-large>Update</buton>
						<buton @click="deleteItem()" class="font-16-60 white--text mt-8 ml-8" color="red" outlined x-large>Delete</buton>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="8">
				<v-row>
					<v-col cols="12" sm="6">
						<v-card class="elevation-0 pa-6 mt-5">
							<v-card-text >
								<div class="font-16-60 black-dark--text">Phone Numbers:</div>
								<div v-if="item.contacts.length > 0" class="mt-5">
									<div class="d-flex justify-space-between">
										<div class="font-14-60 black-dark--text">Personal Phone: </div>
										<v-btn color="primary" @click="editcontacts = true" icon><v-icon samll color="primary">edit</v-icon></v-btn>
									</div>
									<v-simple-table dense class="" v-if="item.contacts && editcontacts">
										<template v-slot:default>
											<tbody v-for="(item, key) in item.contacts" :key="key">
												<tr>
													<td>
														<div><v-text-field v-model="item.name" hide-details></v-text-field></div>
														<div class="">
															<v-text-field v-model="item.phone" hide-details prepend-inner-icon="mdi-plus"></v-text-field>
														</div>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
									<div class="d-flex align-center justify-center" v-if="editcontacts">
										<div class="">
											<v-btn color="green" icon @click="pushContact()"><v-icon >add</v-icon></v-btn>
										</div>
									</div>
									<v-simple-table dense class="" v-if="item.contacts">
										<template v-slot:default>
											<tbody v-for="(item, key) in item.contacts" :key="key">
												<tr v-if="item.phone && item.name">
													<td>
														<div class="font-weight-bold mt-4">
															{{item.name}}
														</div>
														<div v-for="(itm, k) in item.phone.split(',')" :key="k" class="d-flex justify-space-between mt-1">
															<div v-if="itm.length > 2" class="">+{{ itm }}</div>
															<div class="">
																<v-btn v-if="itm.length > 2" color="success" @click="call('+' + itm)" icon><v-icon samll color="success">phone</v-icon></v-btn>
																<v-btn color="black" @click="addPhoneEvent(itm)" fab icon><v-icon color="black">event</v-icon></v-btn>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</div>
								<div v-if="phones.length > 0" class="mt-5">
									<div class="d-flex justify-space-between">
										<div class="font-14-60 black-dark--text">Office:</div>
										<v-btn color="primary" @click="editoffice = true" icon><v-icon samll color="primary">edit</v-icon></v-btn>
									</div>
									<v-text-field v-if="editoffice" v-model="item.phone"></v-text-field>
									<v-simple-table dense class="customsimpledatatable">
										<template v-slot:default>
											<tbody>
												<tr
													v-for="(item, key) in phones"
													:key="key"
												>
													<td>{{ item }}</td>
													<td class="text-right"><v-btn color="success" @click="call(item)" fab icon><v-icon color="success">phone</v-icon></v-btn></td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</div>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" sm="6">
						<v-card class="elevation-0 pa-6 mt-5">
							<v-card-text >
								<div class="font-16-60 black-dark--text">Description:</div>
								<v-textarea v-model="item.description" rows="10"></v-textarea>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-card class="elevation-0 pa-6 mt-5">
					<v-card-title class="text-h5 font-weight-regular px-0">Call History</v-card-title>
					<v-card-text >
						<v-data-table
							:headers="callsheaders"
							:items="item.calls"
							show-expand
						>
							<template v-slot:expanded-item="{ item }">
								<td :colspan="6" v-if="item.record">
									<audio controls class="w-100">
										<source :src="item.record" type="audio/mp3" />
									</audio>
								</td>
							</template>
							<template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
								<v-icon v-if="item.record" :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]" @click.stop="expand(!isExpanded)">$expand</v-icon>
							</template>
							<template v-slot:item.number="{ item }">
								<div class="">
									<div class="">
										<v-icon v-if="item.type == 'out'" small color="green">call_made</v-icon>
										<v-icon v-else-if="item.type == 'in'" small color="blue">call_received</v-icon>
										<v-icon v-else small color="red">call_missed</v-icon>
										{{item.client_number}}
									</div>
								</div>
							</template>
							<template v-slot:item.duration="{ item }">
								<div class="">
									{{Math.floor(item.duration / 60) + " min " + (item.duration % 60 ? item.duration % 60 : '00')}} sec
								</div>
							</template>
							<template v-slot:item.account="{ item }">
								<div class="">
									<div class="" v-if="item.id_user">
										<router-link :to="{ name: 'user-page', params: {id: item.id_user} }" v-html="item.user.name"></router-link>
									</div>
									<div v-else v-html="item.account"></div>
								</div>
							</template>
							<template v-slot:item.operator="{ item }">
								<div class="">
									<div class="">
										<router-link :to="{ name: 'client', params: {id: item.id_user} }" v-html="item.id_user ? item.user.name : item.account"></router-link>
									</div>
								</div>
							</template>
							<template v-slot:item.actions="{item}">
								<div class="d-flex justify-end">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-btn fab icon color="blue" v-bind="attrs" v-on="on" @click="currentItem = item; calldialog = true;">
												<v-icon>{{item.note ? 'textsms' : 'add_comment'}}</v-icon>
											</v-btn>
										</template>
										<span>{{item.note}}</span>
									</v-tooltip>
									<v-btn color="success" fab icon><v-icon color="success">phone</v-icon></v-btn>
								</div>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="calldialog" max-width="500">
			<v-card v-if="currentItem">
				<v-card-title>
					Call Info
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6">
							<div class="font-16-60 my-3">
								Phone: {{currentItem.client_number}}
							</div>
							<div class="font-16-60 my-3">
								Start Call: {{currentItem.start}}
							</div>
							<div class="font-16-60 my-3">
								Wait: {{currentItem.wait}} sec
							</div>
						</v-col>
						<v-col cols="6">
							<div class="font-16-60 my-3">
								Type of call: {{currentItem.type}}
							</div>
							<div class="font-16-60 my-3">
								Duration Call: {{currentItem.duration}} sec
							</div>
							<div v-if="currentItem.record" class="font-16-60 my-3">
								Record: <a :href="currentItem.record">Get Record</a>
							</div>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="currentItem.note" label="Note"></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red" dark @click="dialog = false; currentItem = null">
						Cancel
					</v-btn>
					<v-btn color="green" dark @click="updateCall()">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" color="success" top right>{{snackbartext}}</v-snackbar>
	</div>
</template>

<script>
// import Card from '@/components/base/card'
// import Datatable from '@/components/base/datatable'
import Buton from '@/components/base/buton'
import SnackbarMixin from '@/mixins/snakbarMixin'

export default {
    components: {
        // Card,
        Buton,
		// Datatable
    },
	mixins: [
		SnackbarMixin
	],
    data: () => ({
        item: false,
		categories: [],
		statuses: [],
        phones: [],
		callsheaders: [
            { text: 'Number', value: 'number' },
			{ text: 'Operator', value: 'account' },
            { text: 'Duration (sec)', value: 'duration' },
			{ text: 'CallTime', value: 'start' },
            { text: 'Actions', align: 'end', value: 'actions' },
        ],
		calldialog: false,
		currentItem: null,
		editcontacts: false,
		editoffice: false
    }),
    mounted(){
        this.getItem();
    },
	watch: {
		'$route': function(){
			this.phones = [];
			this.getItem();
		}
	},
    methods: {
        getItem(){
            this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'customer/' + this.$route.params.id).then(response => {
                this.item = response.data;
				this.setPhone(this.item.phone)
				this.prepareItemStatuses();

            })
			this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'customer/params').then((response) => {
				this.categories = response.data.category;
				this.statuses = response.data.status.map(item => {
					return {id: null, id_status: item.id, name: item.name}
				});
			})
        },
		prepareItemStatuses(){
			this.item.statuses = this.item.statuses.map(item => {
				return {id: item.pivot.id, id_status: item.pivot.id_status, name: item.name}
			})
		},
		removeStatus(item){
			if(item.id)
				this.axios.delete(process.env.VUE_APP_AXIOS_API_DOMAIN + 'status/unassign/' + item.id).then(() => {
					this.snackbar = true;
					this.snackbartext = 'Removed';
				});
		},
        updateItem(){
            this.axios.put(process.env.VUE_APP_AXIOS_API_DOMAIN + 'customer/' + this.$route.params.id, this.item).then(() => {
				this.snackbar = true;
				this.snackbartext = 'Client is updated';
			})
        },
		getDomain(){
			return process.env.VUE_APP_BACKEND_DOMAIN;
		},
		formatDate(date){
			var proces = new Date(date);
			return proces.getDate() + '/' + proces.getMonth() + '/' + proces.getFullYear() + ' ' + proces.getHours() + ':' + proces.getMinutes();
		},
		setPhone(data){
			data = data.split(',');
			data.map(item => {
				this.phones.push('+' + item);
			})
		},
		updateCall(){
			this.axios.put(process.env.VUE_APP_AXIOS_API_DOMAIN + 'calls/' + this.currentItem.id, this.currentItem).then(() => {
				this.dialog = false;
				this.currentItem = null;
				this.snackbar = true;
				this.snackbartext = 'The call is updated';
			})
		},
		call(phone){
			var data = { phone: phone, user: this.$store.state.auth.user.moldcell_ext};
			this.axios.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'moldcell/call', data).then(() => {
				this.dialog = false;
				this.currentItem = null;
				this.snackbar = true;
				this.snackbartext = 'Phone number ' + phone + ' is called now';
			})
		},
		goToSite(link){
			window.open('https://' + link);
		},
		deleteItem(){
			if(confirm("Delete Client?"))
				this.axios.delete(process.env.VUE_APP_AXIOS_API_DOMAIN + 'customer/' + this.$route.params.id).then(() => {
					// this.$router.go(-1);
					console.log('ok');
				})
		},
		addCompanyEvent(){
			this.$store.state.task.dialog = true;
			this.$store.state.task.form.id_customer = this.item.id;
			this.$store.state.task.form.name = this.item.title;
		},
		addPhoneEvent(item){
			this.$store.state.task.dialog = true;
			this.$store.state.task.form.id_customer = this.item.id;
			this.$store.state.task.form.name = item;
			this.$store.state.task.form.id_category = 1;

		},
		pushContact(){
			this.item.contacts.push({id: null, name: null,phone: null});
		},
		pushPhone(){
			this.item.phones.push({phone: null});
		},
    }

}
</script>
<style>
.customsimpledatatable .v-data-table__wrapper tbody tr td{
	height:44px !important;
}
</style>
