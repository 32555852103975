<template>
    <div class="drawer h-100" style="width:340px;">
		<div class="drawer__content">
			<div class="py-7">
				<router-link class="ml-8" :to="{ name: 'main' }">
					<img :src="require('@/assets/logo.svg')" alt="Logo Admin">
				</router-link>
			</div>
			<v-list nav class="px-0">
				<v-list-item v-for="(item, i) in items" :key="i" :to="item.to ? {name: item.to} : item.link" @click="checkmethod(item)" :ripple="false">
					<v-list-item-icon>
						<v-icon v-html="item.icon" class="dark--text"></v-icon>
					</v-list-item-icon>
					<v-list-item-content class="py-4">
						<v-list-item-title class="font-14-26 dark--text" v-html="item.name"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</div>
    </div>
</template>

<script>
export default {
    data: ()=>({
        drawer: true,
        items: [
			{
                name: 'Поиск',
                to: 'search',
                icon: 'search'
            },
			{
                name: 'Вызовы',
                to: 'calls',
                icon: 'call_split'
            },
            // {
            //     name: 'Клиенты',
            //     to: 'clients',
            //     icon: 'dashboard'
            // },
            {
                name: 'Клиенты',
                to: 'categories',
                icon: 'badge'
            },
            {
                name: 'Статусы',
                to: 'statuses',
                icon: 'menu_book'
            },
			{
                name: 'Пользователи',
                to: 'users',
                icon: 'group_add'
            },
			{
                name: 'Задачи',
                to: 'tasks',
                icon: 'event'
            },
			{
                name: 'Call',
                // to: 'users',
				link: '#call',
				click: 'newCallDialog',
                icon: 'call'
            },
			{
                name: 'Новая Задача',
                // to: 'users',
				link: '#task',
				click: 'newTaskDialog',
                icon: 'add'
            },
        ]
    }),
	methods: {
		checkmethod(item){
			if(item.click)
				this[item.click]();
		},
		newTaskDialog(){
			this.$store.state.task.dialog = true;
		},
		newCallDialog(){
			this.$store.state.config.caller.dialog = true;
		}
	}
}
</script>
<style lang="scss">
.v-list-item--link.v-list-item--active::before{
	content: none;

}
.v-list-item--link.v-list-item--active{
	background-color: #EFF3FA;
	border-left: 2px solid #1e5cfb;
	border-top-left-radius: 0!important;
	border-bottom-left-radius: 0!important;
	i, .v-list-item__title{
		color: #1e5cfb!important;
	}
}
.drawer{
	height: 100%;
	background-color: #1e5cfb!important;
	.drawer__content{
		height: 100%;
		border-top-right-radius: 70px;
		background-color: white!important;

	}
}
</style>
