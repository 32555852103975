<template>
    <v-select
        v-bind="$attrs"
        v-on="$listeners"
		filled
		class="selectfield-custom"
		hide-details
    >
        <slot/>
    </v-select>
</template>

<script>
export default {

}
</script>
<style lang="scss">
.selectfield-custom{
	.v-input__control{
		.v-input__slot{
			background: transparent!important;
			border: 1px solid #C6CDDA;

		}
		.v-input__slot::before{
			content:none;
		}
		input::placeholder {
			color: #3B3B45 !important;
		}
	}
}
</style>
