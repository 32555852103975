<template>
    <v-app>
        <v-main v-if="$store.state.auth.auth">
			<div class="d-flex h-100">
                <!-- <drawer v-if="$store.state.auth.auth"/> -->
				<drawer/>
				<div class="w-100">
                    <bar/>
					<!-- <bar v-if="$store.state.auth.auth"/> -->
					<main-block/>
				</div>
			</div>
			<task-popup/>
			<call-popup ref="caller"/>
        </v-main>
		<main-block v-if="!$store.state.auth.auth"/>
    </v-app>
</template>

<script>
import Bar from './components/layout/AppBar';
import Drawer from './components/layout/AppDrawer';
import MainBlock from './components/layout/AppMain';
import TaskPopup from './components/page/TaskPopup';
import CallPopup from './components/page/CallPopup';

export default {
    name: 'App',

    components: {
        Bar,
        Drawer,
        MainBlock,
		TaskPopup,
		CallPopup
    },

    data: () => ({
		account: false
    }),
	mounted(){
		this.axios.defaults.withCredentials = true;
		if(!this.$store.state.auth.auth)
			this.$router.replace({name: 'login'});
	},
	watch: {
		$route() {
			this.$store.state.breadCrumbs = [];
		},
	}
};
</script>
<style lang="scss">
.v-main__wrap{
	background: #EFF3FA;
}
</style>
