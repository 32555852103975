<template>
	<div class="appbarmain white--text " flat min-height="80">
		<div class="d-flex justify-space-between pt-6 pb-4 px-8 align-center">
			<div class="font-24-bold" v-html="$route.meta.title"></div>

			<v-spacer></v-spacer>

			<div class="font-14-60 pointer" @click="logout()">
				<v-icon class="white--text">power_settings_new</v-icon>
				Выйти
			</div>
		</div>
		<div v-if="$store.state.breadCrumbs.length > 1">
			<v-breadcrumbs
				:items="$store.state.breadCrumbs"
				class="font-16-60"
			>
				<template v-slot:divider>
					<v-icon>keyboard_arrow_right</v-icon>
				</template>
			</v-breadcrumbs>
		</div>

	</div>
</template>

<script>
export default {
    data: ()=>({
        drawer: true,
		breadCrumbs: []
    }),
	methods:{
		logout(){
			this.$store.dispatch('auth/logout');
		}
	}

}
</script>
<style lang="scss">
	.v-breadcrumbs{
		li{
			a{
				color: white !important;
				opacity: 0.5;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 28px;
			}
			.v-icon{
				color: white;

			}
			div.v-breadcrumbs__item--disabled{
				color: white !important;
				opacity: 1;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 28px;
			}
		}
	}
	.appbarmain{
		background: #1e5cfb;
	}
</style>
