<template>
	<v-dialog
		v-model="$store.state.task.dialog"
		width="800"
	>
		<v-card>
			<v-card-title>Make Task</v-card-title>
			<v-card-text>
				<div class="">
					<v-row>
						<v-col cols="5">
							<v-select v-model="$store.state.task.form.id_category" :items="params.categories" item-text="name" item-value="id" label="Category*"></v-select>
						</v-col>
						<v-col cols="7">
							<v-text-field v-model="$store.state.task.form.name" :label="$store.state.task.form.id_category == 1 ? 'Phone*' : 'Name*'"></v-text-field>
						</v-col>
					</v-row>
					<v-textarea v-model="$store.state.task.form.description" label="Description" rows="2"></v-textarea>
					<v-row>
						<v-col cols="6">
							<v-select v-model="$store.state.task.form.id_status" :items="params.statuses" item-text="name" item-value="id" label="Status*"></v-select>
						</v-col>
						<v-col cols="6">
							<v-select v-model="$store.state.task.form.assign_to" :items="params.users" item-text="name" item-value="id" label="Assign To"></v-select>
						</v-col>
						<v-col cols="6">
							<v-menu
								ref="datemenu"
								v-model="datemenu"
								:close-on-content-click="false"
								:return-value.sync="$store.state.task.form.startdate"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="$store.state.task.form.startdate"
										label="Date*"
										readonly
										v-bind="attrs"
										v-on="on"
										clearable
									></v-text-field>
								</template>
								<v-date-picker
									v-model="$store.state.task.form.startdate"
									no-title
									scrollable
								>
								<v-spacer></v-spacer>
									<v-btn
										text
										color="primary"
										@click="datemenu = false"
									>
										Cancel
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.datemenu.save($store.state.task.form.startdate);timemenu = true"
									>
										OK
									</v-btn>
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="6">
							<v-menu
								ref="timemenu"
								v-model="timemenu"
								:close-on-content-click="false"
								:return-value.sync="$store.state.task.form.starttime"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="$store.state.task.form.starttime"
										label="Time*"
										readonly
										v-bind="attrs"
										v-on="on"
										clearable
									></v-text-field>
								</template>
								<v-time-picker
									v-model="$store.state.task.form.starttime"
									format="24hr"
									scrollable
								>
								<v-spacer></v-spacer>
									<v-btn
										text
										color="primary"
										@click="timemenu = false"
									>
										Cancel
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.timemenu.save($store.state.task.form.starttime);"
									>
										OK
									</v-btn>
								</v-time-picker>
							</v-menu>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
			<v-card-actions class="justify-space-between">
				<div class="">
					<buton @click="$store.dispatch('task/make', $store.state.auth.user.id);snackbar = true; snackbartext = 'Event Created'" class="bg-green font-16-60 white--text mt-8">Make</buton>
					<buton @click="$store.dispatch('task/clear')" class="bg-red ml-2 font-16-60 white--text mt-8">Reset</buton>
				</div>
				<buton @click="$store.dispatch('task/close')" class="secondary font-16-60 white--text mt-8">Cancel</buton>
			</v-card-actions>
		</v-card>
		<v-snackbar v-model="snackbar" color="success" top right>{{snackbartext}}</v-snackbar>
	</v-dialog>
</template>

<script>
import Buton from '@/components/base/buton'
import SnackbarMixin from '@/mixins/snakbarMixin'
export default {
	components: {
		Buton
	},
	mixins: [
		SnackbarMixin
	],
	data: () => ({
		datemenu: false,
		timemenu: false,
		params: []

	}),
	mounted(){
		this.getTaskParams();
	},
	methods: {
		getTaskParams(){
			this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'tasks/params').then(response => {
				this.params = {
					categories: Object.values(response.data.categories),
					statuses: Object.values(response.data.statuses),
					users: Object.values(response.data.users),
				}
			})
		},
		makeTask(){
			this.$store.dispatch('task/make', )
		}
	}
}
</script>

<style lang="css" scoped>
</style>
