<template>
    <div class="d-flex align-center justify-center h-100">
		<v-card class="elevation-12 white pa-8" color="white" style="min-width:650px;">
			<v-card-title class="mb-8 auth">Manager Authorization</v-card-title>
			<v-card-text class="white">
				<div class="mb-5">
					<label class="mb-2 font-14-60" for="">Email</label>
					<text-input v-model="form.email" placeholder="Introduceți email dvs" class="textfield-custom"/>
				</div>
				<div class="">
					<label class="mb-2 font-14-60" for="">Password</label>
					<text-input v-model="form.password" type="password" placeholder="Password" class="textfield-custom"/>
				</div>
				<v-btn class="w-100 blue white--text py-7 mt-8" @click="submit">Log in <v-icon class="ml-4">east</v-icon></v-btn>
			</v-card-text>
		</v-card>
    </div>
</template>

<script>
import TextInput from '@/components/base/inputText'
import Cookie from '@/mixins/CoockieMixin'

export default {
	components: {
		TextInput
	},
	data: () => ({
		form:{
			email: '',
			password: '',
		}
	}),
	mixins: [
		Cookie
	],
	methods: {
		submit () {
			this.$store
				.dispatch('auth/login', this.form)
				.then(() => {
					this.$router.push({ name: 'calls' })
				})
				.catch(err => {
					console.log(err)
				})
		}

	}
}
</script>
<style lang="scss">
.auth{
	font-style: normal!important;
	font-weight: bold!important;
	font-size: 38px!important;
	line-height: 46px!important;
}
</style>
