<template>
	<div class="">
		<v-card>
			<v-card-text>
				<div class="d-flex">
					<input-text
						v-model="$store.state.config.search.searchField"
						append-icon="search"
						class="textfield-custom2"
						placeholder="Search by Company name, Idno, Website, Phone ** Phone without + **"
					/>
					<v-btn color="primary" class="ml-5" x-large @click="getSearch()">Search</v-btn>
				</div>

				<div class="mt-10" v-if="items">
					<datatable
						:headers="datatableheaders"
						:items="items"
						:itemSlots="['item.title', 'item.website']"
					>
						<!-- <template v-slot:item.type="{ item }">
							<router-link :to="{ name: 'request', params: {id: item.id} }" v-html="item.type"></router-link>
						</template> -->
						<template v-slot:item.title="{item}">
							<router-link :to="{name: 'client', params: {id: item.id}}" v-html="item.title"></router-link>
						<!-- <v-btn icon depressed fab :to="{name: 'client', params: {id: item.id}}">
						<v-icon color="primary">visibility</v-icon>
						</v-btn> -->
						</template>
						<template v-slot:item.website="{item}">
							<a :href="'https://' + item.website" target="_blank" v-html="item.website"></a>
						</template>
					</datatable>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import Datatable from '@/components/base/datatable'
import InputText from '@/components/base/inputText'

export default {
	components: {
		InputText,
		Datatable
	},
	data: () => ({
		items: null,
		datatableheaders: [
			{ text: 'Name', value: 'title' },
			{ text: 'IDNO', value: 'idno' },
            { text: 'Website', value: 'website' },
            { text: 'City', value: 'city' },
            // { text: 'Имя', value: 'name' },
            // { text: 'Телефон', value: 'phone' },
            // { text: 'Email', value: 'mail' },
            // { text: 'Actions', value: 'actions' },
        ],
	}),
	watch: {
		'$store.state.config.search.searchField': {
			handler: function() {
				this.getSearch();
			}
		}
	},
	mounted(){
		if(this.$store.state.config.search.searchField.length > 0)
			this.getSearch();
	},
	methods: {
		getSearch(){
			this.axios.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'customer/search', {search: this.$store.state.config.search.searchField}).then(response => {
				this.items = Object.values(response.data)
			})
		}
	}
}
</script>

<style lang="css" scoped>
</style>
