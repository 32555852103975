import axios from 'axios'
import router from '../../router';

export default {
	namespaced: true,

	state: {
		user: null,
		auth: false,
	},

	mutations: {
		setUserData (state, userData) {
			state.user = userData
			localStorage.setItem('user', JSON.stringify(userData))
			axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
			state.auth = true;
		},

		clearUserData (state) {
			state.auth = false;
			state.user = null
			localStorage.removeItem('user')
		}
	},

	actions: {
		login ({ commit }, credentials) {
			return axios
				.post(process.env.VUE_APP_AXIOS_API_DOMAIN + 'user/auth', credentials)
				.then(({ data }) => {
					commit('setUserData', data)
				})
		},

		logout ({ commit }) {
			commit('clearUserData')
			router.push({name: 'login'});
		}
	},

	getters: {
		isLogged: state => !!state.user
	}
}
