<template>
    <div class="">
        <v-btn
            class="mb-3"
            dark
            color="primary"
            @click="makeDialog = true; activeItem = {}"
        >
            <v-icon dark>
                add
            </v-icon>
            Добавить
        </v-btn>
		<div class="d-flex">
            <input-text
				v-model="search"
				append-icon="search"
				class="textfield-custom2"
			/>
        </div>
        <card class="elevation-0">
            <datatable
                :headers="datatableheaders"
                :items="datatabledata"
				:itemSlots="['item.actions', 'item.category']"
				:search="search"
            >
				<template v-slot:item.category="{item}">
					<router-link :to="{ name: 'clients-category', params: {id:item.id} }" v-html="item.category"></router-link>
				</template>
				<template v-slot:item.actions="{item}">
					<v-icon @click="activeItem = item; makeDialog = true;">edit</v-icon>
				</template>
            </datatable>
        </card>
        <v-dialog
            v-model="makeDialog"
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5" v-html="activeItem.category ? 'Edit ' + activeItem.category : 'New Item'"></v-card-title>

                <v-card-text>
                    <input-text v-model="activeItem.category" />
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                    <v-btn
                        color="danger darken-1"
                        text
                        @click="activeItem = false; makeDialog = false;"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="saveItem()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InputText from '@/components/base/inputText'
// import InputSelect from '@/components/base/inputSelect'
import Card from '@/components/base/card'
import Datatable from '@/components/base/datatable'

export default {
    components: {
        // InputSelect,
        InputText,
        Card,
        Datatable
    },
    data: () => ({
		lang: process.env.VUE_APP_LANG,
		search:'',
		datatableheaders: [
            { text: 'ID', value: 'id' },
            { text: 'Название', value: 'category' },
            { text: 'Actions', value: 'actions' },
        ],
        datatabledata: [],
        activeItem: false,
        makeDialog: false
    }),
    mounted(){
        this.getItems();
    },
    methods: {
        getItems(){
            this.axios.get(process.env.VUE_APP_AXIOS_API_DOMAIN + 'category').then((response) => {
                this.datatabledata = response.data;
            })
        },
        saveItem(){
            var url = process.env.VUE_APP_AXIOS_API_DOMAIN + 'category';

            if(this.activeItem.id){
                this.activeItem['_method'] = 'PUT';
                url = url + '/' + this.activeItem.id;
            }

            this.axios.post(url, this.activeItem).then((response) => {
                if(!this.activeItem.id)
                    this.datatabledata.push(response.data);

                this.activeItem = false;
                this.makeDialog = false;

            })
        }
    }
}
</script>
